import * as type from 'constants/action_types/sidebar'
import { SIDEBAR_LOCKED } from 'constants/sidebar'
import { getLocalData, getGlobalData } from 'helpers/storage'
import isEmpty from 'lodash/isEmpty'

const initExpandedOption = () => {
  const globalData = getGlobalData()

  if (isEmpty(globalData)) {
    return null
  }

  return null
}

const sidebarLocked = getLocalData(SIDEBAR_LOCKED)

export const initialState = {
  collapsed: true,
  locked: sidebarLocked === null || sidebarLocked === 'true',
  ignoreToggleState: false,
  expandedOption: initExpandedOption()
}

function sidebar(state = initialState, action) {
  switch (action.type) {
    case type.TOGGLE_SIDEBAR: {
      return {
        ...state,
        collapsed: !state.collapsed,
        ignoreToggleState: false,
        // reset expanded option when closing the sidebar
        expandedOption: !state.collapsed
          ? initialState.expandedOption
          : state.expandedOption
      }
    }
    case type.TOGGLE_SIDEBAR_LOCKED: {
      return {
        ...state,
        locked: action.payload.locked,
        collapsed: !action.payload.locked,
        ignoreToggleState: false,
        // reset expanded option when closing the sidebar
        expandedOption: !action.payload.locked
          ? initialState.expandedOption
          : state.expandedOption
      }
    }
    case type.RESET_SIDEBAR: {
      return {
        ...state,
        // We need to update this based on the size of the window
        collapsed: !action.payload,
        ignoreToggleState: true
      }
    }
    case type.EXPAND_SIDEBAR_OPTION: {
      const { name } = action.payload
      const nextExpandedOption = () => {
        return name === state.expandedOption
          ? initialState.expandedOption
          : name
      }
      return {
        ...state,
        expandedOption: nextExpandedOption()
      }
    }
    default:
      return state
  }
}

export default sidebar
